<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6">
      <v-card class="card-shadow border-radius-xl">
        <v-card-text class="card-padding">
          <v-form ref="frmFilter">
            <v-row class="mt-2">
              <v-col cols="12" md="3">
                <label class="text-md text-typo font-weight-bolder ms-1"
                  >Purchase Type</label
                ><v-form ref="frmType">
                  <v-select
                    v-model="purchaseType"
                    :items="purchaseTypes"
                    item-text="type"
                    item-value="type"
                    color="rgba(0,0,0,.6)"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      border-radius-md
                      select-style
                      mb-0
                      mt-5
                    "
                    placeholder="Select a Purchase Type"
                    :rules="[(v) => !!v || 'Purchase Type is Required']"
                    outlined
                    single-line
                    height="48"
                    @change="getVendor"
                  >
                  </v-select>
                </v-form>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <v-card
        class="card-shadow border-radius-xl mt-5"
        v-if="purchaseType == 'PRIVATE' || purchaseType == 'AUCTION'"
      >
        <v-card-text class="card-padding">
          <!-- Private Search -->
          <v-form ref="frm_private">
            <v-row v-if="purchaseType == 'PRIVATE'">
              <v-col cols="12" md="3">
                <label class="text-md font-weight-bolder ms-1 mandatory"
                  ><span class="red--text"><strong>* </strong></span
                  >Vendor</label
                >
                <v-autocomplete
                  v-model="vendor"
                  :items="vendors"
                  item-text="name"
                  item-value="id"
                  return-object
                  color="rgba(0,0,0,.6)"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    border-radius-md
                    select-style
                    mb-0
                    mt-5
                  "
                  placeholder="Select a Vendor"
                  :rules="[(v) => !!v || 'Vendor is Required']"
                  outlined
                  single-line
                  height="48"
                  @change="getPo"
                >
                </v-autocomplete> </v-col
              ><v-col cols="12" md="3">
                <label class="text-md font-weight-bolder ms-1 mandatory"
                  ><span class="red--text"><strong>* </strong></span>Po
                  Number</label
                >
                <v-autocomplete
                  v-model="poNumber"
                  :items="poNumbers"
                  item-text="po_number"
                  item-value="id"
                  return-object
                  color="rgba(0,0,0,.6)"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    border-radius-md
                    select-style
                    mt-5
                    mb-0
                  "
                  placeholder="Select a PO Number"
                  :rules="[(v) => !!v || 'PO number is Required']"
                  outlined
                  single-line
                  height="48"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>

          <!-- Auction Search -->
          <div v-if="purchaseType == 'AUCTION'">
            <v-form ref="frm_dispatch_auction">
              <v-row>
                <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                  <label class="text-md text-typo font-weight-bolder ms-1"
                    ><span class="red--text"><strong>* </strong></span>Auction
                    center</label
                  >

                  <v-autocomplete
                    :items="auctionCenters"
                    item-text="auction_center_name"
                    item-value="id"
                    hide-details="auto"
                    v-model="auctionCenter"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      border-radius-md
                      select-style
                      mt-3
                      mb-0
                    "
                    outlined
                    height="46"
                    single-line
                    :rules="[(v) => !!v || 'Auction center is required']"
                    placeholder="Select auction center"
                  >
                    <template v-slot:selection="{ item }">
                      <v-text label color="bg-default" class="py-1 px-2 my-0">
                        <span class="text-caption ls-0">{{
                          item.auction_center_name
                        }}</span>
                      </v-text>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                  <label class="text-md text-typo font-weight-bolder ms-1"
                    ><span class="red--text"><strong>* </strong></span
                    >Season</label
                  >

                  <v-autocomplete
                    :items="seasons"
                    item-value="id"
                    hide-details="auto"
                    v-model="season"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      border-radius-md
                      select-style
                      mt-3
                      mb-0
                    "
                    outlined
                    height="46"
                    single-line
                    :rules="[(v) => !!v || 'Season is required']"
                    placeholder="Select Season"
                  >
                    <template v-slot:selection="{ item }">
                      <v-text
                        name="ss_name"
                        label
                        color="bg-default"
                        class="py-1 px-2 my-0"
                      >
                        <span class="text-caption ls-0">{{ item.name }}</span>
                      </v-text>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-text
                        name="ss_name"
                        label
                        color="bg-default"
                        class="py-1 px-2 my-0"
                      >
                        <span class="text-caption ls-0">{{ item.name }}</span>
                      </v-text>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" md="3">
                  <v-btn
                    elevation="0"
                    :ripple="false"
                    height="43"
                    dark
                    class="
                      text-capitalize
                      btn-ls btn-primary
                      bg-success
                      px-6
                      mt-9
                    "
                    @click="getDispatchCode"
                    >Search dispatch Code</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
            <v-form ref="frm_auction">
              <v-row>
                <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                  <label class="text-md text-typo font-weight-bolder ms-1"
                    ><span class="red--text"><strong>* </strong></span>Dispatch
                    Code</label
                  >

                  <v-autocomplete
                    :items="dispatchCodes"
                    item-value="id"
                    item-text="dispatch_code"
                    hide-details="auto"
                    v-model="dispatchCode"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      border-radius-md
                      select-style
                      mt-3
                      mb-0
                    "
                    outlined
                    height="46"
                    single-line
                    :rules="[(v) => !!v || 'Dispatch Code is required']"
                    placeholder="Select Dispatch Code"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </div>

          <v-row>
            <v-col class="d-flex justify-end mx-5">
              <v-btn
                @click="clearfilter"
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  text-capitalize
                  btn-ls btn-secondary
                  bg-light
                  px-6
                  mt-2
                  mr-2
                "
                >Clear</v-btn
              >

              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                dark
                class="text-capitalize btn-ls btn-primary bg-success px-6 mt-2"
                @click="search"
                >Search</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- ## Dialog Dispatch Master -->

      <v-dialog v-model="master_dialog" max-width="700px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Dispatch List</span>
          </v-card-title>
          <v-card-text class="px-0 py-0">
            <v-container>
              <v-data-table
                :items="DispatchMaster"
                :headers="masterHeader"
                mobile-breakpoint="0"
                :footer-props="{
                  'items-per-page-options': [100, 200, 500, -1],
                }"
                fixed-header
                class="table"
                ><template v-slot:[`item.actions`]="{ item }">
                  <v-btn
                    @click="viewDetails(item)"
                    text
                    elevation="0"
                    :ripple="false"
                    height="43"
                    color="red"
                    >View Details</v-btn
                  >
                  <!-- <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
                </template>
              </v-data-table>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!--  ## Data Table Disptach Child List  -->
      <v-form ref="frmTable" v-show="dispatchChildShow">
        <v-card class="card-shadow border-radius-xl mt-6">
          <v-card-text class="px-0 py-0">
            <v-data-table
              v-model="selectedData"
              :headers="headers"
              :items="purchaseDetails"
              item-key="id"
              mobile-breakpoint="0"
              :footer-props="{
                'items-per-page-options': [100, 200, 500, -1],
              }"
              fixed-header
              class="table"
            >
              <template v-slot:top>
                <v-toolbar flat
                  ><v-row
                    ><v-col cols="3">
                      <v-menu
                        ref="mnu_date"
                        v-model="mnu_date"
                        :close-on-content-click="false"
                        :return-value.sync="mnu_date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="date"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            hide-details="auto"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                              mt-5
                            "
                            dense
                            flat
                            filled
                            solo
                            height="43"
                            placeholder="Date"
                            persistent-hint
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          color="green lighten-1"
                          header-color="primary"
                          v-model="date"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="mnu_date = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.mnu_date.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col></v-row
                  >
                  <v-spacer> </v-spacer>
                  <v-btn
                    @click="save"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    dark
                    class="
                      font-weight-bold
                      text-capitalize
                      btn-ls btn-primary
                      bg-success
                      py-3
                      px-6
                    "
                    >Save
                  </v-btn>
                </v-toolbar>
              </template>
              <template v-slot:[`item.received_no_of_packages`]="{ item }">
                <div>
                  <v-text-field
                    v-model.number="item.received_no_of_packages"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    "
                    dense
                    flat
                    filled
                    solo
                    :rules="[
                      (v) => v >= 0 || 'Min should be above 0',
                      (v) =>
                        v <= item.ordered_no_of_packages ||
                        `Max should not be above ${item.ordered_no_of_packages}`,
                    ]"
                    min="0"
                  ></v-text-field>
                </div> </template
              ><template v-slot:[`item.received_quantity`]="{ item }">
                <div>
                  <v-text-field
                    v-model="item.received_quantity"
                    v-on="
                      (item.received_quantity =
                        item.received_no_of_packages * item.unit_weight)
                    "
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    "
                    dense
                    flat
                    filled
                    solo
                    min="0"
                  ></v-text-field>
                </div>
              </template>

              <template v-slot:[`item.bay`]="{ item }">
                <div>
                  <v-text-field
                    v-model="item.bay"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    "
                    dense
                    flat
                    filled
                    solo
                    :rules="[
                      (v) =>
                        item.received_quantity == 0 ||
                        !!v ||
                        'Please Enter Bay',
                    ]"
                  ></v-text-field>
                </div>
              </template>
            </v-data-table> </v-card-text
          ><v-card-actions>
            <v-row>
              <v-col offset-md="5">
                <v-btn
                  @click="openSave"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  dark
                  class="
                    font-weight-bold
                    text-capitalize
                    btn-ls btn-primary
                    bg-success
                    py-3
                    px-6
                  "
                  >Save
                </v-btn></v-col
              >
            </v-row>
          </v-card-actions>
        </v-card>
      </v-form>

      <v-dialog v-model="dialogSave" max-width="500px" persistent>
        <v-card class="card-shadow card-padding border-radius-xl">
          <v-card-title class="pt-0 text-h5 text-typo justify-center"
            >Do you want to Save</v-card-title
          >
          <v-card-actions class="pb-0">
            <v-spacer></v-spacer>
            <v-btn
              @click="closeDailogSave"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls bg-light py-3 px-6"
              >No</v-btn
            >

            <v-btn
              @click="save"
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-bold
                text-capitalize
                btn-ls btn-primary
                bg-success
                py-3
                px-6
              "
              >Yes</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogClosePO" max-width="500px" persistent>
        <v-card class="card-shadow card-padding border-radius-xl">
          <v-card-title class="pt-0 text-h5 text-typo justify-center"
            >Do you want to CLOSE this PO</v-card-title
          >
          <v-card-actions class="pb-0">
            <v-spacer></v-spacer>
            <v-btn
              @click="closeDailogClosePo"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls bg-light py-3 px-6"
              >No</v-btn
            >

            <v-btn
              @click="closePo"
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-bold
                text-capitalize
                btn-ls btn-primary
                bg-success
                py-3
                px-6
              "
              >Yes</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import api from "../api";
import apiMaster from "../../../master/master-get-api";
import apiCommon from "../../delivery-notes/api";
export default {
  data() {
    return {
      //
      auctionCenters: [],
      auctionCenter: null,
      seasons: [],
      season: null,
      seasonSales: [],
      seasonSale: [],
      dispatch_master_id: 0,
      //dispatch Code
      dispatchCodes: [],
      dispatchCode: null,
      //
      purchaseType: "",
      dialogSave: false,
      purchaseDetails: [],
      selectedData: [],
      showTable: false,
      purchaseTypes: [
        {
          type: "AUCTION",
        },
        { type: "PRIVATE" },
      ],
      vendor: null,
      vendors: [],
      poNumber: null,
      poNumbers: [],
      overlay: false,
      dispatchChildShow: false,
      master_dialog: false,
      dialogClosePO: false,
      DispatchMaster: [],
      bay: "",
      masterHeader: [
        {
          text: "Mode Of Transport",
          value: "mode_of_transport",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
        {
          text: "Vehicle Number",
          value: "vehicle_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
        {
          text: "Driver Contact Number",
          value: "driver_contact_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
        {
          text: "",
          value: "actions",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
      ],

      headers: [
        {
          text: "Batch No",
          value: "batch_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
        {
          text: "Grade",
          value: "grade",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
        {
          text: "Invoice No",
          value: "invoice_number",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },

        {
          text: "Item Name",
          value: "item_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
        {
          text: "Mark Name",
          value: "mark_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },

        {
          text: "Unit Weight",
          value: "unit_weight",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
          sortable: false,
        },

        {
          text: "Ordered (Packages)",
          value: "ordered_no_of_packages",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
          sortable: false,
        },
        {
          text: "Recieved (Packages)",
          value: "received_no_of_packages",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
          sortable: false,
        },

        {
          text: "Order Quantity (KGs)",
          value: "ordered_quantity",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
          sortable: false,
        },
        {
          text: "Recieved Quantity (KGs)",
          value: "received_quantity",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
          sortable: false,
        },
        {
          text: "Bay",
          value: "bay",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
          sortable: false,
        },
      ],
      //
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      mnu_date: false,
    };
  },
  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.auctionCenters = await apiMaster.getAuctionCenter();

      this.seasons = await apiMaster.getSeason();
    },
    async filterSeasonSale() {
      this.overlay = true;
      this.seasonSale = null;
      this.seasonSales = await apiMaster.getSeasonSaleBySeasonAndAuctionCenter(
        this.season,
        this.auctionCenter
      );
      this.overlay = false;
    },
    async getVendor() {
      if (this.purchaseType == "PRIVATE")
        this.vendors = await api.getVendorsByType(this.purchaseType);
    },
    async getPo() {
      if (this.validatefrmType()) {
        try {
          let req = {
            vendor: this.vendor.id,
            type: this.purchaseType,
          };

          this.poNumbers = await api.getPo(req);
        } catch (error) {}
      } else {
        this.vendor = "";
      }
    },
    closeDailogSave() {
      this.dialogSave = false;
    },

    frmPrivateValidate() {
      return this.$refs.frm_private.validate();
    },
    frmDispatchAuctionValidate() {
      return this.$refs.frm_dispatch_auction.validate();
    },
    frmAuctionValidate() {
      return this.$refs.frm_auction.validate();
    },

    async getDispatchCode() {
      if (this.frmDispatchAuctionValidate()) {
        this.overlay = true;
        this.dispatchCodes =
          await api.getConfirmedDispatchMasterBySeasonAndAuctionCenter(
            this.auctionCenter,
            this.season
          );
        this.overlay = false;
      }
    },

    async search() {
      if (this.purchaseType == "PRIVATE") {
        // this.$refs.frm_dispatch_auction.resetValidation();
        if (this.frmPrivateValidate()) {
          try {
            this.overlay = true;
            this.DispatchMaster = await api.getDispatchMaster(
              this.poNumber.po_number,
              this.purchaseType
            );
            this.overlay = false;
            this.master_dialog = true;
            this.dispatchChildShow = false;
            this.purchaseDetails = [];
            this.selectedData = [];

            console.log("this.result", this.DispatchMaster);
          } catch (error) {
            console.log(error);
          }
        }
      }
      if (this.purchaseType == "AUCTION") {
        this.$refs.frm_private.resetValidation();
        if (this.frmAuctionValidate()) {
          try {
            this.overlay = true;
            this.DispatchMaster = await api.getDispatchMasterById(
              this.dispatchCode
            );
            this.overlay = false;
            this.master_dialog = true;
            this.dispatchChildShow = false;
            this.purchaseDetails = [];
            this.selectedData = [];

            console.log("this.result", this.DispatchMaster);
          } catch (error) {
            console.log(error);
          }
        }
      }
    },

    async viewDetails(item) {
      this.dispatch_master_id = item.id;
      let result = await api.getPurchasedetailsForUpdateDispatch(item.id);

      this.purchaseDetails = result;
      this.selectedData = this.purchaseDetails;
      this.master_dialog = false;
      this.dispatchChildShow = true;
    },
    validateFrmAuction() {
      return this.$refs.frm.validate();
    },
    validatefrmType() {
      return this.$refs.frmType.validate();
    },

    validTable() {
      return this.$refs.frmTable.validate();
    },

    openSave() {
      this.dialogSave = true;
    },

    // Update Dipatch And Inventory After item Recieved.
    async save() {
      this.dialogSave = false;
      if (this.selectedData.length > 0) {
        if (this.validTable()) {
          if (this.purchaseType == "PRIVATE") {
            this.overlay = true;
            let req = {
              child: this.selectedData,
              po_number: this.poNumber.po_number,
              purchase_type: this.purchaseType,

              delivery_date: this.date,
            };
            console.log(req);
            try {
              await api.update(req);

              this.overlay = false;
              this.dialogClosePO = true;
            } catch (error) {
              // console.log(error);
              this.closeDailogClosePo();
              this.overlay = false;
              this.showErrorAlert(error);
            }
          }
          if (this.purchaseType == "AUCTION") {
            this.overlay = true;
            let req = {
              child: this.selectedData,
              dispatch_master_id: this.dispatch_master_id,
              purchase_type: this.purchaseType,
              bay: this.bay,
              delivery_date: this.date,
            };
            console.log(req);
            try {
              await api.update(req);

              this.overlay = false;
              // this.dialogClosePO = true;
              this.closeDailogClosePo();
            } catch (error) {
              this.closeDailogClosePo();
              this.overlay = false;
              this.showErrorAlert(error);
            }
          }
        }
      } else {
        this.showWarningAlert("Select Atleast one Item");
      }
    },

    closeDailogClosePo() {
      this.dialogClosePO = false;
      this.clear();
      this.showSuccessAlert("Dispatch updated");
    },

    clear() {
      this.selectedData = [];
      this.purchaseDetails = [];
      this.dispatchChildShow = false;
    },
    clearfilter() {
      this.vendor = null;
      this.vendors = [];
      this.poNumber = null;
      this.poNumbers = [];
      this.auctionCenter = null;
      this.season = null;
      this.seasonSale = [];
      this.dispatch_master_id = 0;
      this.dispatchCodes = [];
      this.dispatchCode = null;
      this.purchaseDetails = [];
      this.selectedData = [];
      this.$refs.frmFilter.resetValidation();
      this.$refs.frmType.resetValidation();
    },
    async closePo() {
      try {
        console.log(this.poNumber);
        await api.closePo(this.poNumber.po_number);
        this.showSuccessAlert("Dispatch updated and PO closed");
        this.dialogClosePO = false;
        this.clear();
      } catch (error) {
        this.showErrorAlert(error);
      }
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",

        icon: "error",
        showConfirmButton: true,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },
    noDataAlert(message) {
      this.$swal({
        text: message,

        timer: 3000,
      });
    },
  },
};
</script>

<style></style>
