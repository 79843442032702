import axios from "axios";

export default {
  async getVendorsByType(type) {
    return await axios
      .get("vendors/vendor/by/type/" + type)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getCompanyWareHouse() {
    return await axios
      .get("/company-warehouse")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getVendors() {
    return await axios
      .get("vendors")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getPo(req) {
    return await axios
      .get(`purchase-auction/get-po/by-vendor?vendor_id=${req.vendor}&purchase_type=${req.type}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getDispatchMaster(po_number, purchase_type) {
    return await axios
      .get(`/dispatch/master-by-po-number/${po_number}?purchase_type=${purchase_type}`)
      .then((res) => {
        console.log(res.data);

        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getDispatchMasterAuction(season_sale) {
    return await axios
      .get(`/dispatch/master-by-po-number-auction/${season_sale}`)
      .then((res) => {
        console.log(res.data);

        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getDispatchMasterById(id) {
    return await axios
      .get(`/dispatch/master-by-id/${id}`)
      .then((res) => {
        console.log(res.data);

        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getPurchaseDeatils(po_number, purchase_type) {
    return await axios
      .get(`/purchase-auction/purchase/details/${encodeURIComponent(po_number)}?purchase_type=${purchase_type}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getPurchaseDetailsByPoNumbers(params) {
    return await axios
      .get(`purchase-auction/purchase/details-by-po-numbers`, {
        params: params,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getPurchaseDetailsByPoNumbersForAuctionDispatch(params) {
    return await axios
      .get(`purchase-auction/purchase/details-by-po-numbers-for-auction-dispatch`, {
        params: params,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getPurchasedetailsForUpdateDispatch(master_id) {
    return await axios
      .get(`/dispatch/dispatch/details/for-update/${master_id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getConfirmedDispatchCode(season_sales) {
    return await axios
      .get(`dispatch/dispatch-code`, { params: { season_sales } })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getConfirmedDispatchMasterBySeasonAndAuctionCenter(auction_center, season) {
    return await axios
      .get(`dispatch/dispatch-confirmed-master-by-auctioncenter-season?auction_center=${auction_center}&season=${season}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async createDispatch(req) {
    return await axios
      .post("/dispatch", req)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async update(req) {
    return await axios
      .patch("/dispatch", req)
      .then((res) => {
        console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw new Error(err.response.data.message);
      });
  },
  async closePo(po_number) {
    return await axios
      .patch("/purchase-auction/close-PO/" + po_number)
      .then((res) => {
        console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw new Error(err.response.data.message);
      });
  },
  async getAuctionVenders(season_sale) {
    return await axios
      .get(`vendors/season-sales`, { params: { season_sales: season_sale } })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getAuctionVendorsBySales(season_sale) {
    return await axios
      .get(`vendors/season-sale/${season_sale}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getPoByVendorsAndSeasonSale(params) {
    return await axios
      .get(`purchase-auction/vendors-po`, { params: params })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
};
